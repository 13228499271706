.card__showcase{
    background-image: url('../../assets/blob-scene-hori.svg');
    background-color: #F0F0F0;
    font-family: var(--font-family);
    background-size: cover;
    background-position: center;
}

.dark__box{
    height: 25px;
    background-color: #040C18;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.dark__box_new{
  margin-top: 1rem;
  height: 25px;
  background-color: #040C18;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.showcase-title{
  margin: 2rem 0 1rem;
  padding: 1rem 1.5rem 1rem 2rem;
  border-radius: 0 25px 25px 0; 
  background-color: #D9DDE3;
  width:fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.showcase-title-center{
  margin: 2rem auto 1rem auto;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 25px 25px; 
  background-color: #D9DDE3;
  width:fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.showcase-icon{
  width: 45px;
  height: auto;
  transition: all 250ms;
  padding: 0px 10px;
}

.showcase-icon:hover{
  transition: all 250ms;
  transform: scale(1.1);
}

.showcase-links{
  margin: 1rem 0px 2rem auto;
  padding: 1rem 1.5rem 0.5rem 2rem;
  border-radius: 25px 0px 0px 25px; 
  background-color: #D9DDE3;
  width:fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.thumbnail-list {
    flex-direction: row;
    justify-content: left;
    list-style: inside;
    text-align: center;
    white-space: wrap;
  
  }

  img{
    border-radius: 25px 25px 25px 25px; 
    width: 400px;
  }
  
  .thumbnail-item {
    display: inline-block;
    text-align: center;
    margin: auto;
    transition: all 300ms;
    padding: 18px 30px;
  }

  div.card:hover{
    color: rgb(255, 0, 0);
    transition: all 250ms;
    transform: scale(1.1);
  }
  
  div.card {
    transition: all 250ms;
    width: 400px;
    height: 300px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    border-radius: 20px;
  }

  div.card-small {
    transition: all 250ms;
    width: 400px;
    height: 300px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    border-radius: 20px;
  }

  div.inner-card{
    color: rgba(255, 0, 0, 0);
    font-family: var(--font-family);
    width: 100%;
    height: 100%;
    /* padding: 75px; */
    border-radius: 20px;
    transition: all 300ms ease-in-out;
  }

  div.inner-text{
    color: inherit;
    text-align: center;
    padding: 20% 10%;
    font-size: 120%;
  }

  div.card div.inner-card:hover{
    color: rgb(255, 255, 255);
    background-color:#001223da;
    transition: all 300ms ease-in-out;
  }

  .one{
    background-image: url('../../assets/card_tapas.png');
  }

  .two{
    background-image: url('../../assets/card_csuf.png');
  }  

  .three{
    background-image: url('../../assets/card_spectraloverdrive.png');
  }  
  
  

  .inner-card p{
    color: #fff;
    font-family: var(--font-family);
  }
  
  span:hover {
    opacity: 0.8;
    transform: scale(1.1);
    transition: all 300ms;
    
  }

@media screen and (max-width: 1050px) {
    div.card {
        width: 18rem;
        height: 13rem;
    }
    div.inner-text{
      color: inherit;
      text-align: center;
      padding: 10% 5%;
    }
    .thumbnail-item {
      padding: 20px 15px;
    }
}

@media screen and (max-width: 700px) {
    div.card {
        width: 20rem;
        height: 15rem;
    }
    .card__showcase{
      background-image: url('../../assets/blob-scene-veri.svg');
    }
}

@media screen and (max-width: 550px) {
    div.card {
        width: 60vw;
        height: 45vw;
        font-size: 80%;
    }
    div.inner-text{
      padding: 20% 5%;
    }
    .thumbnail-item {
      padding: 15px 40px;
    }
    .showcase-links{
      padding: 1rem 1rem 0.5rem 1.5rem;
    }
    
}

@media screen and (max-width: 450px) {
  div.card {
      width: 60vw;
      height: 45vw;
      font-size: 75%;
  }
  div.inner-text{
    padding: 13% 5%;
  }
  .thumbnail-item {
    padding: 10px 40px;
  }
}

@media screen and (max-width: 370px) {
  .showcase-title{
    font-size: 16px;
    width: 80vw;
  }

  .showcase-title-center{
    font-size: 12px;
  }
}

@media screen and (max-width: 350px) {
  div.card {
      font-size: 60%;
  }
  div.inner-text{
    padding: 10% 10%;
  }
}
  
@media screen and (max-width: 280px) {
  .showcase-title{
    font-size: 14px;
  }
  div.card {
      font-size: 50%;
  }
  div.inner-text{
    padding: 15% 10%;
  }
  .subtext{
    display: none;
  }
}
  