p, h1, h2, h3, h4, b, .portfolio-list{
    font-family: var(--font-family);
}

.tapas-banner{
    background-image: url(../../assets/blob-scene-tapas-1.svg);
    background-color: #EEBC0D;
    background-size: cover;
    background-position: center;
    padding: 10px;
}

.csuf-banner{
    background-image: url(../../assets/blob-scene-csuf.svg);
    background-color: #00274C;
    background-size: cover;
    background-position: center;
    padding: 10px;
}

.spectral-banner{
    background-image: url(../../assets/blob-scene-so.svg);
    background-color: #00274C;
    background-size: cover;
    background-position: center;
    padding: 10px;
}

.banner-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.main-portfolio{
    background-color: #F0F0F0;
}

.portfolio-text{
    padding: 2rem 6rem;
    width: 80vw;
    margin: 0 auto;
}

.job-title{
    font-size: 24px;
    margin-right: 24px;
}

.main-portfolio h1{
    font-size: 48px;
}

.main-portfolio p, b, .portfolio-list{
    font-size: 18px;
}

.main-portfolio ul{
    margin-left: 24px;
}

.exp-title{
    margin: 2rem 0rem 0rem;
    padding: 1rem 2rem 1rem 5rem;
    border-radius: 0 25px 25px 0; 
    background-color: #D9DDE3;
    width:fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }

.portfolio-text div{
    margin-bottom: 1rem;
}

.gallery-container > a {
    max-width: 200px;
    max-height: 150px;
    padding: 5px;
    display: inline-block;
}

.gallery-div{
    margin: 10px auto;
}

#lg-gallery .thumb-cont .thumb-inner {
    margin-left: auto;
    margin-right: auto;
}

.lightbox-img{
    padding: 10px;
    height: auto;
    width: 20vw;
    transition: all 200ms ease-in-out;
}

.lightbox-img:hover{
    opacity: 80%;
    transition: all 200ms ease-in-out;
}

.center-gallery{
    width: 60vw;
    margin: 10px auto;
}

@media screen and (max-width: 1250px) {
    .portfolio-text{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 700px) {
    .portfolio-text{
        width: 100%;
    }
    .lightbox-img{
        width: 100%;
    }

}

@media screen and (max-width: 550px) {
    .main-portfolio h1{
        font-size: 32px;
    }

    .banner-image{
        width: 100%;
    }
}

@media screen and (max-width: 370px) {}

@media screen and (max-width: 350px) {
    .portfolio-text{
        padding: 2rem 2rem;
    }
}

@media screen and (max-width: 280px) {}

/* @media screen and (max-width: 1050px) {}

@media screen and (max-width: 700px) {}

@media screen and (max-width: 550px) {}

@media screen and (max-width: 370px) {}

@media screen and (max-width: 350px) {}

@media screen and (max-width: 280px) {} */