.about-pic{
    margin-right: 2rem;
    width: 300px;
    flex: 1;
}

.main-about{
    background-image: url('../../assets/blob-scene-hori.svg');
    background-size: cover;
    background-position: center;
}

.about-blurb p{
    font-size: 18px;
    width: 600px;
}

.about-container{
    margin: 2rem auto;
    width: 850px;
}

.section {
    display: -webkit-flex;
    display: flex;
}

.aboutpic{
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.section {
    display: -webkit-flex;
    display: flex;
  }
  
  .nav2 {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right:  20px;
    margin-bottom: 20px;
  }

.textbox{
    margin-bottom: 20px;
    border-radius: 25px; 
    background-color: #D9DDE3;
    padding: 1rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

  @media (max-width: 1000px) {
    .about-container{
        width: 90vw;
    }
  }
  
  @media (max-width: 900px) {
    .section {
      -webkit-flex-direction: column;
      flex-direction: column;
    }
    .nav2{
      padding: 10px;
      margin: auto;
    }
  }

  @media (max-width: 700px){
    .main-about{
        background-image: url('../../assets/blob-scene-veri.svg');
    }
  }

  @media (max-width: 400px) {
    .aboutpic{
        width: 80vw;
    }
  }