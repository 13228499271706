.header__container{
    background-image: url(../../assets/bgtest1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px 60%;
}

.header {
    display: flex;
    flex-direction: row;

    padding: 2rem 6rem;
    background-image: url(../../assets/test2.png);
    background-repeat: repeat;
    background-position: 0px 50%;
}

.header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    /* margin-right: 5rem; */
}

.header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    width: 450px;

    /* margin-top: 0.5rem; */
}

.header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.header-image img {
    width: 450px;
    padding: 1rem  2rem 0rem 0rem;
        
    /* height: 100%; */
}

.header-image {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: right;
}

@media screen and (max-width: 1000px) {
    .header-image img {
        width: 450px;
        padding: 1rem  2rem 0rem 2rem;
            
        /* height: 100%; */
    }

    .header-content p {
        width: auto;
    }

    .header-content h1 {
        font-size: 300%;
    }

    .header {
        flex-direction: column;
        background-image: url(../../assets/test2.png);
    }

    .header-image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-content {
        margin: 0 0 0rem;
    }
}

@media screen and (max-width: 650px) {

    .header {
        padding: 1.5rem;
    }

    .header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .header-image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-content p {
        font-size: 20px;
    }
    
    .header-content__people {
        flex-direction: column;
    }

    .header-content__people p {
        margin: 0;
    }

    .header-content__input input,
    .header-content__input button {
        font-size: 16px;
    }
}

@media screen and (max-width: 490px) {
    .header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .header-content p {
        font-size: 16px;
    }

    .header-image img {
        width: 80vw;
        padding: 1rem;
        /* height: 100%; */
    }

    .header-content__input input,
    .header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (max-width: 400px) {
    .header-content h1 {
        font-size: 32px;
    }

    .header-content p {
        font-size: 16px;
    }

    .header-image img {
        width: 80vw;
        padding: 1rem;
        /* height: 100%; */
    }

    .header-content__input input,
    .header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}