@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap");

.tapas-title{
    color: white;
}

.tapas-container {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  .tapas-gallery {
    width: min(100% - 15px, 900px);
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 10px;
    .tapas-card {
      position: relative;
      height: 350px;
      flex: 1;
      overflow: hidden;
      border-radius: 25px;
      cursor: pointer;
      filter: grayscale(60%);
      transition: all 1s cubic-bezier(0.2, 0.2, 0.25, 1.0);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      .tapas-info {
        width: 100%;
        position: absolute;
        z-index: 3;
        padding: 15px;
        bottom: 0;
        opacity: 0;
        transition: opacity 1s cubic-bezier(0.25, 0.4, 0.45, 1.8);
        box-shadow: inset 0 -120px 120px -120px black,
          inset 0 -120px 120px -120px black;
      }
      &:hover {
        flex: 2;
        filter: none;
        .tapas-info {
          opacity: 1;
        }
      }
    }
  }
}

// mobile view
@media only screen and (max-width: 768px) {
  .tapas-container {
    .tapas-gallery {
      width: 100%;
      margin: 10px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .tapas-card {
        height: 200px;
        .tapas-info {
          width: 100%;
          padding: 10px;
        }
        &:nth-child(3n + 3) {
          grid-column: 1/3;
        }
      }
    }
  }
}


.spectral-container {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  .spectral-gallery {
    width: min(100% - 15px, 800px);
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 10px;
    .spectral-card {
      position: relative;
      height: 250px;
      flex: 1;
      overflow: hidden;
      border-radius: 25px;
      cursor: pointer;
      filter: grayscale(60%);
      transition: all 1s cubic-bezier(0.2, 0.2, 0.25, 1.0);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
      .spectral-info {
        width: 100%;
        position: absolute;
        z-index: 3;
        padding: 15px;
        bottom: 0;
        opacity: 0;
        transition: opacity 1s cubic-bezier(0.25, 0.4, 0.45, 1.8);
        box-shadow: inset 0 -120px 120px -120px black,
          inset 0 -120px 120px -120px black;
      }
      &:hover {
        flex: 2;
        filter: none;
        .spectral-info {
          opacity: 1;
        }
      }
    }
  }
}

// mobile view
@media only screen and (max-width: 768px) {
  .spectral-container {
    .spectral-gallery {
      width: 100%;
      margin: 10px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .spectral-card {
        height: 200px;
        .spectral-info {
          width: 100%;
          padding: 10px;
        }
        &:nth-child(3n + 3) {
          grid-column: 1/3;
        }
      }
    }
  }
}